import {createNotificationForResponse, errorNotification, successNotification} from '../utils/Notifications';
import {apiGatewayUrl as ApiUrl, DICTIONARY_CACHE_NAME, HTTP_DELETE, HTTP_GET, HTTP_POST} from '../utils/Consts';
import AuthHeader from './AuthHeader';
import {createSaveAddressPersonalRequest} from '../utils/MapperUtils';
import {getCredentials, getIdWithoutPrefix} from '../services/UserDataService';

const URL = ApiUrl;

class FetchService {
    getCaptcha = async () => {
        const response = await fetch(URL + 'getCaptcha', {
            method: HTTP_GET,
            headers: AuthHeader()
        });
        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    }

    createAccount = async (captchaAnswer, captchaId, email, language) => {
        const response = await fetch(URL + 'candidate-user/accounts', {
            method: HTTP_POST,
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: JSON.stringify({
                // "captchaAnswer": captchaAnswer,
                // "captchaId": captchaId,
                "email": email
                // "language": language
            })
        })
        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    }

    activateAccount = async (activationLink, password) => {
        const response = await fetch(URL + 'profileActivate', {
            method: HTTP_POST,
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                "activationLink": activationLink,
                "password": password
            })
        })
        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    }

    resetPassword = async (activationLink, password) => {
        const response = await fetch(URL + 'passwordReset', {
            method: HTTP_POST,
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                "activationLink": activationLink,
                "password": password
            })
        })
        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    }

    sendResetLink = async (captchaAnswer, captchaId, email, language) => {
        const response = await fetch(URL + 'sendResetLink', {
            method: HTTP_POST,
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                "captchaAnswer": captchaAnswer,
                "captchaId": captchaId,
                "email": email,
                "language": language
            })
        })
        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    }

    sendPhotoToValidate = async (photo, customValidation, translate) => {
        const response = await fetch(URL + 'kadrowanie/walidacja', {
            method: HTTP_POST,
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({photo: photo, ...customValidation}),
        });

        if (response.ok) {
            return await response.json();
        } else {
            const error = await response.json();
            let isErrorOccured = false;
            error.errorCode.forEach(code => {
                if (translate(`cropPhoto.sendPhotoNotification.errorCode.${code}`) !== `cropPhoto.sendPhotoNotification.errorCode.${code}`) {
                    errorNotification('cropPhoto.sendPhotoNotification.errorValidaitonTitle', `cropPhoto.sendPhotoNotification.errorCode.${code}`);
                    isErrorOccured = true;
                }
            });

            if (!isErrorOccured) {
                errorNotification('default.unexpected.error', '');
            }

            return null;
        }
    };

    sendPhotoToSave = async photo => {
        const response = await fetch(URL + 'kadrowanie/zapis', {
            method: HTTP_POST,
            headers: AuthHeader(),
            body: JSON.stringify({photo: photo}),
        });

        if (response.ok) {
            successNotification('cropPhoto.sendPhotoNotification.successTitle', '');
            return await response.json();
        } else {
            errorNotification('default.unexpected.error', '');
            return null;
        }
    };

    checkIfPhotoExists = async () => {
        const response = await fetch(URL + 'kadrowanie/checkIfPhotoExists', {
            method: HTTP_GET,
            headers: AuthHeader()
        });

        if (response.ok) {
            return await response.json();
        } else {
            errorNotification('default.unexpected.error', '');
            return null;
        }
    };

    getSavedPhoto = async () => {
        const response = await fetch(URL + 'kadrowanie/photo', {
            method: HTTP_GET,
            headers: AuthHeader()
        });

        if (response.ok) {
            return await response.json();
        } else {
            errorNotification('default.unexpected.error', '');
            return null;
        }
    };

    getAddressPersonalDictionary = async () => {
        const response = await fetch(URL + 'dictionary/addressPersonal', {
            method: HTTP_GET,
            headers: AuthHeader()
        });
        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    };

    getSchoolDictionary = async () => {
        const response = await fetch(URL + 'dictionary/school', {
            method: HTTP_GET,
            headers: AuthHeader()
        });
        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    };

    getSchoolSecondDegreeDictionary = async () => {
        const response = await fetch(URL + 'dictionary/schoolSecondDegree', {
            method: HTTP_GET,
            headers: AuthHeader()
        });
        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    };

    getSchoolThirdDegreeDictionary = async () => {
        const response = await fetch(URL + 'dictionary/schoolThirdDegree', {
            method: HTTP_GET,
            headers: AuthHeader()
        });
        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    };

    getKREMSchoolNamesDictionary = async () => {
        const response = await fetch(URL + 'dictionary/schoolName', {
            method: HTTP_GET,
            headers: AuthHeader()
        });
        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    };

    checkDictionaryKeyExistsInCache = async (type, key) => {
        const response = await fetch(URL + 'cache/check', {
            method: HTTP_POST,
            headers: AuthHeader(),
            body: JSON.stringify({
                "name": DICTIONARY_CACHE_NAME,
                "type": type,
                "key": key
            })
        });

        if (response.ok) {
            return await response.json();
        }
    };

     saveAddressPersonal = async (inputPersonalData) => {
        const response = await fetch(URL + 'addressPersonal/save', {
            method: HTTP_POST,
            headers: AuthHeader(),
            body: createSaveAddressPersonalRequest(inputPersonalData)
        });

        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    };

    getAddressPersonal = async () => {
        const response = await fetch(URL + 'addressPersonal', {
            method: HTTP_POST,
            headers: AuthHeader(),
            body: getIdWithoutPrefix()
        });

        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    };

    getMessages = async () => {
        const response = await fetch(URL + 'getAllMessages', {
            method: HTTP_GET,
            headers: AuthHeader()
        });
        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    };

    getAvailableRecruitmentModes = async () => {
        const response = await fetch(URL + 'getAvailableRecruitmentModes/' + getIdWithoutPrefix(), {
            method: HTTP_GET,
            headers: AuthHeader()
        });
        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    };

    getChosenRecruitmentModes = async () => {
        const response = await fetch(URL + 'getChosenRecruitmentModes/' + getIdWithoutPrefix(), {
            method: HTTP_GET,
            headers: AuthHeader()
        });
        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    };

    getRecruitmentMode = async (recruitmentModeKey) => {
        const response = await fetch(URL + 'getChosenRecruitmentModes/' + getIdWithoutPrefix() + '/' + recruitmentModeKey, {
            method: HTTP_GET,
            headers: AuthHeader()
        });
        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    };

    addRecruitmentMode = async (modeKey) => {
        const response = await fetch(URL + 'chosenRecruitmentModes/' + getIdWithoutPrefix() + '/' + modeKey, {
            method: HTTP_POST,
            headers: AuthHeader(),
        });

        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    };

    deleteRecruitmentMode = async (modeKey) => {
        const response = await fetch(URL + 'chosenRecruitmentModes/' + getIdWithoutPrefix() + '/' + modeKey, {
            method: HTTP_DELETE,
            headers: AuthHeader(),
        });

        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    };

    getSchoolData = async (modeKey) => {
        const response = await fetch(URL + 'graduatedschool/' + getIdWithoutPrefix() + '/' + modeKey, {
            method: HTTP_GET,
            headers: AuthHeader()
        });
        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    };

    saveSchoolData = async (data, modeKey) => {
        const response = await fetch(URL + 'graduatedschool/' + getIdWithoutPrefix() + '/' + modeKey, {
            method: HTTP_POST,
            headers: AuthHeader(),
            body: JSON.stringify(data)
        });

        if (response.ok) {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
            return await json;
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    };

    getCourses = async (modeKey, recruitmentModeKey ) => {
        const response = await fetch(URL + 'courses/', {
            method: HTTP_POST,
            headers: AuthHeader(),
            body: JSON.stringify({
                "studyDegree": modeKey,
                "recruitmentModeKey": recruitmentModeKey
            })
        });
        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    };
    
    getCandidateCourses = async (modeKey, recruitmentModeKey) => {
        const response = await fetch(URL + 'courses/candidate/' + getIdWithoutPrefix(), {
            method: HTTP_POST,
            headers: AuthHeader(),
            body: JSON.stringify({
                "studyDegree": modeKey, //todo: do usunięcia
                "recruitmentModeKey": recruitmentModeKey
            })
        });
        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    };
    saveCandidateCourses = async (selectedCourses, recruitmentModeKey) => {
        const response = await fetch(URL + 'courses/save/', {
            method: HTTP_POST,
            headers: AuthHeader(),
            body: JSON.stringify({
                "profileId": getIdWithoutPrefix(),
                "selectedCourses": selectedCourses,
                "recruitmentModeKey": recruitmentModeKey
            })
        });
        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    };

    getFilesInfo = async (modeKey, formName) => {
        const response = await fetch(URL + 'files/' + formName + '/' + getIdWithoutPrefix() + '/' + modeKey, {
            method: HTTP_GET,
            headers: AuthHeader()
        });

        if (response.ok) {
            return await response.json()
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status)
        }
    }

    saveFiles = async (request) => {
        const response = await fetch(URL + 'candidateFiles/files/' + getIdWithoutPrefix(), {
            method: HTTP_POST,
            headers: AuthHeader(),
            body: JSON.stringify(request)
        });
        if (response.ok) {
            let json = await response.json();
            if (json.wiadomoscTyp !== 'success') {
                createNotificationForResponse(json, "", response.status);
            }
            return json;
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    }

    downloadFile = async (fileId) => {
        const response = await fetch(URL + 'candidateFiles/file/' + getIdWithoutPrefix() + '/' + fileId, {
            method: HTTP_GET,
            headers: AuthHeader()
        });

        if (response.ok) {
            return await response.json()
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status)
        }
    }

    postChangeFileState = async (inputData) => {
        const response = await fetch(URL + 'files/changeStatus', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...AuthHeader() // Assuming AuthHeader() returns the necessary authentication headers
            },
            body: JSON.stringify(inputData)
        });

        if (response.ok) {
            let content = await response.json();
            return content;
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    };

    createPayment = async (recruitmentModeKey, courseId) => {
        const response = await fetch(URL + 'payment/' + getIdWithoutPrefix() + '/' + recruitmentModeKey + '/' + courseId, {
            method: HTTP_POST,
            headers: AuthHeader()
        });

        if (response.ok) {
            return await response.json()
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status)
        }
    }

    sendVerificationCode = async () => {
        const response = await fetch(URL + 'smsApi/sendVerificationCode/', {
            method: HTTP_POST,
            headers: AuthHeader(),
            body: getCredentials()
        });
        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    };

    isVerificationCodeValid = async (code) => {
        const response = await fetch(URL + 'smsApi/isVerificationCodeValid/', {
            method: HTTP_POST,
            headers: AuthHeader(),
            body: JSON.stringify({
                "code": code,
                "email": getCredentials()
            })
        });
        if (response.ok) {
            // if(json.wiadomoscTyp !== 'success') {
            // createNotificationForResponse(json, "", response.status);
            // }
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status);
        }
    };

    getDates = async (trybSemester) => {
        const response = await fetch('https://e-rekrutacja-gateway.test.p.lodz.pl/getDates', {
            method: HTTP_POST,
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: JSON.stringify({
                "trybSemester": trybSemester,
            })
        });

        if (response.ok) {
            return await response.json()
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status)
        }
    }

    getPhotoStatus = async () => {
        const response = await fetch(URL + 'photostatus/get/' + getIdWithoutPrefix(), {
            method: HTTP_GET,
            headers: AuthHeader(),
        });

        if (response.ok) {
            return await response.json()
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status)
        }
    }

    savePhotoStatus = async () => {
        const response = await fetch(URL + 'photo/save' , {
            method: HTTP_POST,
            headers: AuthHeader(),
            body: JSON.stringify({
                "profileId": getIdWithoutPrefix(),
                "status": null,
                "description": "Dodano nowe zdjęcie",
                "operator": getCredentials()
            })
        });

        if (response.ok) {
            return await response.json()
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status)
        }
    }
    
    setDecisionDate = async () => {
        const response = await fetch(URL + 'decyzje/setDecisionAsRead', {
            method: HTTP_POST,
            headers: AuthHeader(),
            body: getIdWithoutPrefix()
        });

        if (response.ok) {
            return await response.json()
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status)
        }
    }

    savePriority = async ({
                              id,
                              priority,
                              recruitmentMode
                          }) => {
        const response = await fetch(URL + 'courses/savePriority', {
            method: HTTP_POST,
            headers: AuthHeader(),
            body: JSON.stringify({
                "id": id,
                "priority": priority,
                "profileId": getIdWithoutPrefix(),
                "recruitmentMode": recruitmentMode
            })
        });

        if (response.ok) {
            return await response.json()
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "", response.status)
        }
    }

    getAnnouncement = async (type, typeId, language) => {

        const response = await fetch(URL + `announcements/getAnnouncement/${language}`, {
            method: HTTP_GET,
            headers: AuthHeader()
        })

        if (response.ok) {
            return await response.json();
        } else {
            let json = await response.json();
            createNotificationForResponse(json, "serviceDisabledError", response.status);
        }
    }

}

export default new FetchService();